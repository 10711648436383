import locale6010b66b from '../../lang/en-US.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","messages":{}},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","name":"English","file":"en-US.js"},{"code":"fr","iso":"fr-FR","name":"Français","file":"fr-FR.js"},{"code":"ar","iso":"ar-TN","name":"العربية","file":"ar-TN.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/mr-fe/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","name":"English","file":"en-US.js"},{"code":"fr","iso":"fr-FR","name":"Français","file":"fr-FR.js"},{"code":"ar","iso":"ar-TN","name":"العربية","file":"ar-TN.js"}],
  localeCodes: ["en","fr","ar"],
}

export const localeMessages = {
  'en-US.js': () => Promise.resolve(locale6010b66b),
  'fr-FR.js': () => import('../../lang/fr-FR.js' /* webpackChunkName: "lang-fr-FR.js" */),
  'ar-TN.js': () => import('../../lang/ar-TN.js' /* webpackChunkName: "lang-ar-TN.js" */),
}
